import { QueryFunction } from 'react-query';
import queryClient from '@/lib/query-client';
import { getBranding } from './services/branding.service';

export enum AppDataQueryKey {
  Branding = 'branding',
}

const HOUR = 1000 * 60 * 60;

type QueryOptions = { cacheTime: number; staleTime: number };

export const AppDataQueries: Map<
  AppDataQueryKey,
  { queryFn: QueryFunction; options: QueryOptions }
> = new Map();

// Organization branding
AppDataQueries.set(AppDataQueryKey.Branding, {
  queryFn: getBranding,
  options: {
    staleTime: HOUR * 24,
    cacheTime: HOUR * 48,
  },
});

export const prefetchAppData = async () => {
  const prefetchQueries: Promise<void>[] = [];

  for (const [queryKey, { queryFn, options }] of AppDataQueries) {
    prefetchQueries.push(queryClient.prefetchQuery(queryKey, queryFn, options));
  }

  return Promise.all(prefetchQueries);
};

export const getAppData = <T>(queryKey: AppDataQueryKey) => queryClient.getQueryData<T>(queryKey);
