import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { ThemeProvider as JssThemeProvider } from 'react-jss';
import Theme from './theme';
import CssBaseline from '@mui/material/CssBaseline';
import { AppProvider } from './contexts/app';
import { QueryClientProvider } from 'react-query';
import queryClient from './lib/query-client';
import { SnackbarProvider } from 'notistack';
import { ModalProvider } from './contexts/modal';
import RouteProvider from './router/provider';
import { initDatadog } from './lib/datadog';
import Environment from './lib/environment';
import ErrorBoundary from './components/error-boundary';

initDatadog();
console.log(`build v${APP_VERSION}-${Environment.MODE}`);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <MuiThemeProvider theme={Theme}>
      <JssThemeProvider theme={Theme}>
        <CssBaseline />
        <ErrorBoundary>
          <AppProvider>
            <QueryClientProvider client={queryClient}>
              <ModalProvider>
                <SnackbarProvider>
                  <RouteProvider />
                </SnackbarProvider>
              </ModalProvider>
            </QueryClientProvider>
          </AppProvider>
        </ErrorBoundary>
      </JssThemeProvider>
    </MuiThemeProvider>
  </React.StrictMode>
);
