import { JsonObject } from '@/lib/helpers';
import { AlertColor } from '@mui/material';
import { FormikConfig, FormikValues, useFormik } from 'formik';
import { useCallback, useState } from 'react';
import * as yup from 'yup';

export type FormMessage = { level: AlertColor; content: string };

export type FormHandle<T extends FormikValues> = ReturnType<typeof useFormik<T>> & {
  validationSchema: yup.ObjectSchema<JsonObject>;
  canSubmit: boolean;
  canCancel: boolean;
  formMessage: FormMessage;
  setFormMessage: (formMessage: FormMessage) => void;
  loadData: (values: FormikValues) => void;
  handleCancel: () => void;
  updateValidationSchema: (validationSchema: yup.ObjectSchema<T>) => void;
  readonly: boolean;
};

const useFormHandle = <T extends FormikValues>(config: FormikConfig<T>): FormHandle<T> => {
  const [formMessage, setFormMessage] = useState<FormMessage>({ level: 'info', content: '' });

  const [validationSchema, setValidationSchema] = useState<yup.ObjectSchema<JsonObject>>(
    (config.validationSchema || yup.object({})) as yup.ObjectSchema<JsonObject>
  );

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: false,
    ...config,
    validationSchema,
  });

  const { isSubmitting, dirty, isValid, validateOnChange, resetForm, initialValues } = formik;

  const canSubmit = !isSubmitting && dirty && (!validateOnChange || isValid);
  const canCancel = !isSubmitting && dirty;

  const loadData = useCallback(
    (values: T) => {
      resetForm({ values });
    },
    [resetForm]
  );

  const handleCancel = useCallback(() => {
    loadData(initialValues);
  }, [loadData, initialValues]);

  const updateValidationSchema = (updatedSchema: yup.ObjectSchema<JsonObject>) => {
    setValidationSchema(updatedSchema);
  };

  return {
    ...formik,
    validationSchema,
    canSubmit,
    formMessage,
    setFormMessage,
    loadData,
    handleCancel,
    canCancel,
    updateValidationSchema,
    readonly: false,
  };
};

export default useFormHandle;
