import { Dialog, Theme } from '@mui/material';
import { FC, ReactNode } from 'react';
import useModal from '../../hooks/use-modal.hook';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: Theme) => ({
  modal: {
    '& .MuiDialogActions-root': {
      marginTop: 20,
      padding: '20px 24px 10px 24px',
      borderTop: `1px solid ${theme.palette.grey[200]}`,
      gap: 8,
    },
  },
  backdrop: {
    backgroundColor: 'rgb(255 255 255 / 70%)',
  },
  paper: {
    boxShadow: 'none',
    border: `1px solid ${theme.palette.grey[400]}`,
  },
}));

interface Props {
  children: ReactNode;
}

export const Modal: FC<Props> = ({ children }) => {
  const { closeModal } = useModal();
  const handleClose = () => closeModal();
  const styles = useStyles();

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      className={styles.modal}
      PaperProps={{ className: styles.paper }}
      slotProps={{ backdrop: { className: styles.backdrop } }}
    >
      {children}
    </Dialog>
  );
};

export default Modal;
