import { FC, useEffect, useState } from 'react';
import Page from '../../components/page';
import { Box, Stack } from '@mui/material';
import Text from '@/components/text';
import Icon from '@/components/icon';
import PageCard from '@/components/page-card';
import { LoadState } from '@/lib/helpers';
import { addError } from '@/lib/datadog';

interface Props {
  error?: Error;
}

const ErrorPage: FC<Props> = ({ error }) => {
  const [loadState, setLoadState] = useState<LoadState>('unloaded');

  useEffect(() => {
    if (!error) {
      setLoadState('loaded');
      return;
    }

    if (loadState !== 'unloaded') {
      return;
    }

    const renderError = new Error(error.message);
    renderError.name = `ReactRenderingError`;
    renderError.stack = error.stack;
    renderError.cause = error;
    addError(renderError);

    setLoadState('loaded');
  }, [loadState, error]);

  return (
    <Page>
      <PageCard>
        <Stack alignItems="center" justifyContent="center" gap={2}>
          <Box>
            <Icon name="network-error" size="x-large" color="error" />
          </Box>
          <Box>
            <Text size="x-large">Unexpected Error</Text>
          </Box>
          <Box mt={2}>There was an unexpected error. Please try again later.</Box>
        </Stack>
      </PageCard>
    </Page>
  );
};

export default ErrorPage;
