import { ChangeEvent, FC } from 'react';
import MuiCheckbox from '@mui/material/Checkbox';
import { createUseStyles } from 'react-jss';
import { FormControlLabel } from '@mui/material';
import FormControl from '../form-control';
import { FormProps } from '..';

const useStyles = createUseStyles({
  checkbox: {
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
});

interface Props extends FormProps {
  checked: boolean;
  onChange: (checked: boolean, event: ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox: FC<Props> = ({
  name,
  checked,
  label = '',
  disabled = false,
  autoFocus = false,
  onChange,
  error = '',
}) => {
  const styles = useStyles();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
    onChange(event.target.checked, event);

  const InputEl = (
    <MuiCheckbox
      name={name}
      checked={checked}
      onChange={handleChange}
      color="primary"
      className={styles.checkbox}
      disabled={disabled}
      autoFocus={autoFocus}
    />
  );

  return (
    <FormControl label="" error={error} name={name}>
      <FormControlLabel control={InputEl} label={label} />
    </FormControl>
  );
};

export default Checkbox;
