import { FC, ReactNode } from 'react';
import MuiLoadingButton from '@mui/lab/LoadingButton';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import Icon, { IconType } from '../icon';

type ButtonType = 'ok' | 'submit' | 'cancel' | 'delete';

type ButtonStyleProps = {
  color: 'secondary' | 'primary' | 'inherit' | 'error' | 'success';
  variant: 'contained' | 'outlined';
  inputType: 'button' | 'submit';
};

interface Props {
  label?: ReactNode;
  disabled?: boolean;
  size?: 'large' | 'medium' | 'small';
  type?: ButtonType;
  fullWidth?: boolean;
  className?: string;
  icon?: IconType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (event: any) => void; // @todo event type?
  loading?: boolean;
}

const useStyles = createUseStyles({
  button: {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    '&.MuiButton-sizeSmall': {
      height: 40,
    },
  },
  icon: {
    marginRight: 12,
  },
});

const useStyleProps = (type: ButtonType): ButtonStyleProps => {
  switch (type) {
    case 'ok':
    default:
      return { color: 'success', variant: 'contained', inputType: 'button' };
    case 'cancel':
      return { color: 'inherit', variant: 'contained', inputType: 'button' };
    case 'delete':
      return { color: 'secondary', variant: 'contained', inputType: 'button' };
    case 'submit':
      return { color: 'success', variant: 'contained', inputType: 'submit' };
  }
};

const Button: FC<Props> = ({
  label,
  disabled = false,
  size = 'large',
  icon,
  fullWidth = false,
  className = '',
  onClick,
  type = 'ok',
  loading = false,
}) => {
  const styles = useStyles();
  const { color, variant, inputType } = useStyleProps(type);
  const compClass = classNames(className, styles.button);

  let LabelEl: ReactNode = label;

  if (icon) {
    LabelEl = (
      <>
        <Icon name={icon} className={styles.icon} />
        {label}
      </>
    );
  }

  return (
    <MuiLoadingButton
      color={color}
      className={compClass}
      disabled={disabled}
      size={size}
      variant={variant}
      type={inputType}
      fullWidth={fullWidth}
      onClick={onClick}
      loading={loading}
    >
      {LabelEl}
    </MuiLoadingButton>
  );
};

export default Button;
