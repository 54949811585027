import { AxiosResponse } from 'axios';
import { JsonObject } from '../helpers';

export type ApiResponse = JsonObject | JsonObject[] | null;

export type DeleteReponse = { deleted: boolean; error?: string };

export type UpdateResponse = { updated: boolean; error?: string };

export const handleResponse = (response: AxiosResponse | null): ApiResponse => {
  if (!response?.data) {
    return null;
  }

  if (Array.isArray(response?.data)) {
    return response?.data as JsonObject[];
  }

  return response?.data as JsonObject;
};
