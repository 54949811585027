import { FC } from 'react';
import { Box } from '@mui/material';
import Button from '../../components/button';

import { createUseStyles } from 'react-jss';
import * as yup from 'yup';
import InputControl from '../../components/form/input-control';
import useFormHandle from '../../hooks/use-form-handle.hook';
import { AuthMethod, getAuthMethods } from '../../lib/services/auth.service';

const useStyles = createUseStyles({
  footer: {
    marginTop: 10,
    marginBottom: 10,
  },
});

const validationSchema = yup.object({
  email: yup.string().email('Please enter a valid email').required('Please enter your email'),
});

const initialValues = {
  email: '',
};

interface Props {
  onChange: (email: string, authMethods: AuthMethod[]) => void;
}

const IdentForm: FC<Props> = ({ onChange }) => {
  const styles = useStyles();

  const formHandle = useFormHandle({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const niceEmail = String(values.email).trim();
      const authMethods = await getAuthMethods(niceEmail);
      onChange(niceEmail, authMethods);
      setSubmitting(false);
    },
    validateOnChange: false,
  });

  const { handleSubmit, isSubmitting } = formHandle;

  return (
    <form onSubmit={handleSubmit}>
      <InputControl name="email" label="Email" formHandle={formHandle} autoFocus />
      <Box className={styles.footer}>
        <Button label="Sign In" fullWidth disabled={isSubmitting} onClick={handleSubmit} />
      </Box>
    </form>
  );
};

export default IdentForm;
