import { BreadCrumb } from '@/components/app-header/helpers';
import { AppDataQueryKey, getAppData, prefetchAppData } from '@/lib/app-data';
import { setDatadogUser } from '@/lib/datadog';
import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import {
  AuthType,
  AuthUser,
  checkAuth,
  getRedirectUrl,
  observeTokenChange,
  refreshSessionCookie,
  signOut,
} from '../../lib/services/auth.service';
import { AppContext, AppSettings, DefaultBranding, SpBranding } from './helpers';
import { BrandingModel } from '@/lib/models/branding.model';
import { deleteCachedBranding, setCachedBranding } from '@/lib/services/branding.service';

interface Props {
  children: ReactNode;
}

/*
 * Rather than immediately affecting a redirect, some routes let the redirect querystring
 * parameter "pass through" to the page so it can be consumed another way. This, and other
 * actions, should really be handled by the router, but the overall UX and performance seems
 * to be better when managed here, before further rendering.
 */
const isPassThruPath = (path: string): boolean => ['/logout'].includes(path);

export const AppProvider: FC<Props> = ({ children }) => {
  const handleSignOut = useCallback(async (): Promise<boolean> => {
    return signOut().then((response) => {
      if (!response) {
        return response;
      }

      setSettings((old) => ({
        ...old,
        signedIn: false,
        signInError: '',
        signInMethod: null,
        user: null,
      }));

      return true;
    });
  }, []);

  const handleSignIn = (authUser: AuthUser, method: AuthType): boolean => {
    setSettings((old) => ({
      ...old,
      user: authUser,
      signInError: '',
      signInMethod: method,
      signedIn: true,
    }));

    return true;
  };

  const handleResetSignIn = (): boolean => {
    setSettings((old) => ({
      ...old,
      signedIn: false,
      signInError: '',
      signInMethod: null,
      user: null,
    }));
    return true;
  };

  const [settings, setSettings] = useState<AppSettings>({
    loadState: 'unloaded',
    signedIn: false,
    signInError: '',
    signInMethod: null,
    signIn: handleSignIn,
    signOut: handleSignOut,
    resetSignIn: handleResetSignIn,
    user: null,
    pageTitle: { plain: '' },
    setPageTitle: (plain: string, breadcrumb?: BreadCrumb | BreadCrumb[]) => {
      setSettings((old) => ({
        ...old,
        pageTitle: { plain, breadcrumb },
      }));
    },
    branding: DefaultBranding,
  });

  // run once on boot
  useEffect(() => {
    const { loadState } = settings;
    if (loadState !== 'unloaded') {
      return;
    }

    setSettings((old) => ({
      ...old,
      loadState: 'loading',
    }));
  }, [settings]);

  useEffect(() => {
    const { loadState } = settings;
    if (loadState !== 'loading') {
      return;
    }

    const loadAuth = async () => {
      const { user, error: signInError, method } = await checkAuth();
      const signedIn = Boolean(user);

      if (signedIn) {
        // ensure that recently pulled auth credentials are immediately available to services, and stay in sync with firebase auth state
        observeTokenChange();

        setDatadogUser(user!.uid, user!.email || '');
        const interceptId = await refreshSessionCookie();

        if (!isPassThruPath(window.location.pathname)) {
          const redirectUrl = getRedirectUrl(interceptId);
          if (redirectUrl) {
            window.location.href = redirectUrl;
            return;
          }
        }
        prefetchAppData().then(() => {
          const branding = getAppData<BrandingModel>(AppDataQueryKey.Branding);

          const hasBranding = !!branding?.enabled;

          if (hasBranding) {
            setCachedBranding(branding);
          } else {
            deleteCachedBranding();
          }

          setSettings((old) => ({
            ...old,
            user,
            signedIn,
            signInError,
            signInMethod: method,
            branding: hasBranding ? branding : SpBranding,
            loadState: 'loaded',
          }));
        });

        return;
      }

      setSettings((old) => ({
        ...old,
        user,
        signedIn,
        signInError,
        signInMethod: method,
        loadState: 'loaded',
      }));
    };

    loadAuth();
  }, [settings]);

  return <AppContext.Provider value={settings}>{children}</AppContext.Provider>;
};
