import { FC } from 'react';
import { createUseStyles } from 'react-jss';
import useApp from '@/hooks/use-app.hook';

const useStyles = createUseStyles({
  brandLogo: {
    maxWidth: 250,
    maxHeight: 40,
    width: 'auto',
    height: 'auto',
  },
});

const BrandLogo: FC = () => {
  const styles = useStyles();
  const { branding } = useApp();

  const { name = '', lightThemeLogo } = branding;
  const hasLogo = !!lightThemeLogo;

  if (!hasLogo) {
    return null;
  }

  return <img src={lightThemeLogo} alt={name} className={styles.brandLogo} />;
};

export default BrandLogo;
