import { FormControl as MuiFormControl, InputLabel, FormHelperText, Theme } from '@mui/material';
import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  name: string;
  label?: string;
  error?: string;
  className?: string;
  fullWidth?: boolean;
  children: ReactNode;
}

const useStyles = createUseStyles((theme: Theme) => ({
  helperText: {
    marginLeft: 0,
  },
  formControl: {
    '& div.MuiInputBase-root': {
      '& fieldset': {
        borderColor: `${theme.palette.common.black} !important`,
      },
    },
  },
}));

const FormControl: FC<Props> = ({
  className = '',
  name,
  children,
  label = '',
  error = '',
  fullWidth = true,
}) => {
  const styles = useStyles();
  const hasError = !!error;
  const hasLabel = !!label;
  const labelId = `${name}-label`;
  const compClass = classNames(className, styles.formControl);

  return (
    <MuiFormControl fullWidth={fullWidth} className={compClass} margin="normal">
      {hasLabel && <InputLabel id={labelId}>{label}</InputLabel>}
      {children}
      {hasError && (
        <FormHelperText className={styles.helperText} error>
          {error}
        </FormHelperText>
      )}
    </MuiFormControl>
  );
};

export default FormControl;
