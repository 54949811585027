import { FC } from 'react';
import Icon, { IconType } from '../icon';
import { Box, Theme } from '@mui/material';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

interface Props {
  label: string;
  icon?: IconType;
  color?: 'black' | 'grey' | 'link' | 'error';
  onClick: () => void;
}

const useStyles = createUseStyles((theme: Theme) => ({
  flatButton: {
    cursor: 'pointer',
    '& .label': {
      marginLeft: 8,
      marginTop: 5,
      lineHeight: '100%',
      verticalAlign: 'top',
    },
    '&.grey': {
      color: theme.palette.grey[400],
    },
    '&.link': {
      color: theme.palette.link.main,
    },
    '&.error': {
      color: theme.palette.error.main,
    },
  },
}));

const FlatButton: FC<Props> = ({ label, icon, onClick, color }) => {
  const styles = useStyles();
  const IconEl = icon ? <Icon name={icon} /> : null;
  const compClass = classNames(styles.flatButton, color);

  return (
    <Box className={compClass} display="inline-block" onClick={() => onClick()}>
      {IconEl}
      <Box className="label" display="inline-block">
        {label}
      </Box>
    </Box>
  );
};

export default FlatButton;
