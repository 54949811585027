import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import Environment from '../environment';

const firebaseApp = initializeApp({
  apiKey: Environment.FIREBASE_API_KEY,
  authDomain: Environment.FIREBASE_AUTH_DOMAIN,
});

export const firebaseAuth = getAuth(firebaseApp);
