import { BreadCrumb } from '@/components/app-header/helpers';
import { LoadState } from '@/lib/helpers';
import { AuthType, AuthUser } from '@/lib/services/auth.service';
import { createContext } from 'react';
import SurepathLogoCarbon from '@/assets/logo-carbon-0924.svg';
import { BrandingModel } from '@/lib/models/branding.model';
import { getCachedBranding } from '@/lib/services/branding.service';

export interface AppSettings {
  loadState: LoadState;
  signedIn: boolean;
  signIn: (authUser: AuthUser, authType: AuthType) => boolean;
  signOut: () => Promise<boolean>;
  resetSignIn: () => boolean;
  signInError: string;
  signInMethod: AuthType | null;
  pageTitle: { plain: string; breadcrumb?: BreadCrumb | BreadCrumb[] };
  setPageTitle: (title: string, breadcrumb?: BreadCrumb | BreadCrumb[]) => void;
  user: AuthUser | null;
  branding: BrandingModel;
}

const CachedBranding = getCachedBranding();

export const SpBranding = new BrandingModel({
  name: 'SurePath AI',
  supportEmail: '',
  lightThemeLogo: SurepathLogoCarbon,
  favicon: '/favicon-cobalt-0924.png',
});

export const DefaultBranding = CachedBranding?.enabled ? CachedBranding : SpBranding;

export const AppContext = createContext<AppSettings>({
  loadState: 'unloaded',
  signedIn: false,
  signInError: '',
  signInMethod: null,
  signIn: () => false,
  signOut: () => Promise.resolve(false),
  resetSignIn: () => false,
  user: null,
  pageTitle: { plain: '' },
  setPageTitle: () => null,
  branding: DefaultBranding,
});
