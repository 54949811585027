import { Box, Container, Stack, Theme, Toolbar } from '@mui/material';
import { Outlet } from '@tanstack/react-router';
import { FC } from 'react';

import { createUseStyles } from 'react-jss';
import AppHeader from './components/app-header';
import Text from './components/text';
import ModalRoot from './contexts/modal/modal-root';
import useApp from './hooks/use-app.hook';
import { useDocumentTitle } from 'usehooks-ts';
import useFavicon from './hooks/use-favicon.hook';

const useStyles = createUseStyles((theme: Theme) => ({
  main: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  content: {
    margin: '15px auto',
    flexGrow: 1,
    maxHeight: 'calc(100% - 94px)',
  },
  '@global': {
    input: {
      '&:-webkit-autofill': {
        background: 'none',
        backgroundColor: 'transparent !important',
        backgroundClip: 'text',
      },
    },
    a: {
      color: theme.palette.action.active,
      textDecoration: 'none',
      '&:hover': {
        opacity: 0.7,
      },
      '&.flat': {
        color: theme.palette.common.black,
      },
    },
  },
}));

const App: FC = () => {
  const styles = useStyles();
  const { signedIn, pageTitle, branding } = useApp();
  const { plain } = pageTitle;
  const hasPageTitle = Boolean(plain);
  const hasPageTitlePlain = Boolean(plain);

  const documentTitle = branding?.name;
  const favicon = branding?.favicon || '/favicon-blank.png';

  useDocumentTitle(`${documentTitle}${hasPageTitlePlain ? ' |' : ''} ${plain}`);

  useFavicon(favicon);

  return (
    <>
      <Box display="flex">
        <AppHeader />
        <Box
          component="main"
          className={styles.main}
          display="flex"
          flexDirection="column"
          alignItems={signedIn ? 'flex-start' : 'center'}
        >
          <Toolbar />
          <Container className={styles.content}>
            {!signedIn && hasPageTitle && (
              <Stack alignItems="center">
                <Text size="x-large" noWrap>
                  {plain}
                </Text>
              </Stack>
            )}
            <Outlet />
          </Container>
        </Box>
      </Box>
      <ModalRoot />
    </>
  );
};

export default App;
