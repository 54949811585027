import { Backdrop, Stack, Theme } from '@mui/material';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';
import Loader from '../loader';
import BrandLogo from '../brand-logo';

const useStyles = createUseStyles((theme: Theme) => ({
  loader: {
    zIndex: theme.zIndex.drawer + 1000,
    backgroundColor: theme.palette.common.white,
  },
}));

const PageLoader: FC = () => {
  const styles = useStyles();

  return (
    <Backdrop className={styles.loader} open={true}>
      <Stack gap={3} alignItems="center" justifyContent="center" width="300px">
        <BrandLogo />
        <Loader />
      </Stack>
    </Backdrop>
  );
};

export default PageLoader;
