export type IEnvironment = {
  FIREBASE_API_KEY: string;
  FIREBASE_AUTH_DOMAIN: string;
  DATADOG_APPLICATION_ID: string;
  DATADOG_CLIENT_TOKEN: string;
  DATADOG_SERVICE: string;
  DATADOG_SITE: string;
  MODE: string;
  APP_VERSION: string;
  SP_API_URL: string;
  SP_GATEWAY_QUERY_PARAM: string;
  ATLAS_DATASOURCE: string;
  ATLAS_DATABASE: string;
};

const meta = import.meta.env;

const Environment: IEnvironment = Object.freeze({
  SP_API_URL: (meta.VITE_SP_API_URL as string) || '',
  FIREBASE_API_KEY: (meta.VITE_FIREBASE_API_KEY as string) || '',
  FIREBASE_AUTH_DOMAIN: (meta.VITE_FIREBASE_AUTH_DOMAIN as string) || '',
  DATADOG_APPLICATION_ID: (meta.VITE_DATADOG_APPLICATION_ID as string) || '',
  DATADOG_CLIENT_TOKEN: (meta.VITE_DATADOG_CLIENT_TOKEN as string) || '',
  DATADOG_SERVICE: (meta.VITE_DATADOG_SERVICE as string) || '',
  DATADOG_SITE: (meta.VITE_DATADOG_SITE as string) || '',
  MODE: meta.MODE,
  APP_VERSION, // https://stackoverflow.com/questions/67194082/how-can-i-display-the-current-app-version-from-package-json-to-the-user-using-vi
  SP_GATEWAY_QUERY_PARAM: (meta.VITE_SP_GATEWAY_QUERY_PARAM as string) || '',
  ATLAS_DATASOURCE: (meta.VITE_ATLAS_DATASOURCE as string) || '',
  ATLAS_DATABASE: (meta.VITE_ATLAS_DATABASE as string) || '',
});

export const isProd = () => Environment.MODE === 'production';

export default Environment;
