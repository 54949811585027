import axios, { AxiosResponse } from 'axios';
import Environment from '../environment';
import { JsonObject } from '../helpers';
import { AuthProvider } from './auth.service';
import { ApiResponse } from './index';

export type FindAndCountResult = { documents: JsonObject[]; total: number };

export type MongoSort = Record<string, number> | Record<string, number>[];

export const SAFE_LIMIT = 1000;

const axiosClient = axios.create({
  baseURL: `${Environment.SP_API_URL}/data`,
  timeout: 10000,
  responseType: 'json',
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosClient.interceptors.request.use((config) => {
  config.headers.jwtTokenString = AuthProvider.token;
  return config;
});

export const findOne = async (
  collection: string,
  params: JsonObject
): Promise<JsonObject | null> => {
  return axiosClient
    .post('/action/findOne', { ...getDefaultQueryParams(), collection, ...params })
    .then(handleResponse)
    .then((response: JsonObject | null) => (response?.document as JsonObject) || null);
};

export const getOidParam = (id: string) => ({ $oid: id });

export const getOidParams = (ids: string[]) => ids.map(getOidParam);

const getDefaultQueryParams = (): { dataSource: string; database: string } => {
  return { dataSource: Environment.ATLAS_DATASOURCE, database: Environment.ATLAS_DATABASE };
};

const handleResponse = (response: AxiosResponse | null): ApiResponse => {
  if (!response?.data) {
    return null;
  }

  if (Array.isArray(response?.data)) {
    return response?.data as JsonObject[];
  }

  return response?.data as JsonObject;
};
