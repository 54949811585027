import { findOne, getOidParam } from '@/lib/services/atlas-data-api.service';
import { JsonObject } from '@/lib/helpers';
import { get as getLocal, set as setLocal, remove as removeLocal } from '../local-storage';
import { BrandingModel } from '../models/branding.model';
import { AuthProvider } from './auth.service';

const BRANDING_CACHE_KEY = 'sp-branding';

const getOrgBrandingByOrgId = async (orgId: string): Promise<BrandingModel | null> => {
  const params = {
    filter: {
      _id: getOidParam(orgId),
    },
    projection: {
      id: 1,
      name: 1,
      branding: 1,
    },
  };

  const response = await findOne('orgs', params);

  if (!response) {
    return null;
  }

  return new BrandingModel({
    ...(response.branding as JsonObject),
    name: response.name,
    id: response.id,
  });
};

const getPartnerBrandingByPartnerId = async (partnerId: string): Promise<BrandingModel | null> => {
  const params = {
    filter: {
      _id: getOidParam(partnerId),
    },
    projection: {
      id: 1,
      name: 1,
      branding: 1,
    },
  };

  const response = await findOne('partners', params);

  if (!response) {
    return null;
  }

  return new BrandingModel({
    ...(response.branding as JsonObject),
    name: response.name,
    id: response.id,
  });
};

export const getBranding = async (): Promise<BrandingModel | null> => {
  if (AuthProvider.orgId) {
    return getOrgBrandingByOrgId(AuthProvider.orgId);
  }

  if (AuthProvider.partnerId) {
    return getPartnerBrandingByPartnerId(AuthProvider.partnerId);
  }

  return null;
};

export const getCachedBranding = (): BrandingModel | null => {
  const brandingJson = getLocal(BRANDING_CACHE_KEY, true) as JsonObject;

  if (!brandingJson) {
    return null;
  }

  return new BrandingModel(brandingJson);
};

export const setCachedBranding = (branding: BrandingModel) => {
  setLocal(BRANDING_CACHE_KEY, branding._json);
};

export const deleteCachedBranding = () => {
  removeLocal(BRANDING_CACHE_KEY);
};
