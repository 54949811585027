import { FC } from 'react';
import Page, { PageProps } from '../../components/page';
import { useSearch } from '@tanstack/react-router';
import { SplashPageAction } from '@/router';
import SplashMessage from './splash-message';
import PageCard from '@/components/page-card';

const SplashPage: FC<PageProps> = () => {
  const { action, destination, traceId, code } = useSearch({ from: '/splash' });

  return (
    <Page>
      <PageCard>
        <SplashMessage
          action={action as SplashPageAction}
          destination={destination}
          traceId={traceId}
          code={code}
        />
      </PageCard>
    </Page>
  );
};

export default SplashPage;
