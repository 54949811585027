import { FC, useCallback, useEffect, useState } from 'react';
import Page, { PageProps } from '../../components/page';
import { useNavigate } from '@tanstack/react-router';
import useApp from '../../hooks/use-app.hook';
import { getRedirectParams } from '@/lib/services/auth.service';
import PageCard from '@/components/page-card';
import Icon from '@/components/icon';
import Text from '@/components/text';
import { Box } from '@mui/material';
import Button from '@/components/button';
import InlineToast from '@/components/inline-toast';

const LogoutPage: FC<PageProps> = () => {
  const navigate = useNavigate();
  const { signOut } = useApp();
  const [error, setError] = useState('');

  const handleSignOut = useCallback(() => {
    signOut().then((response) => {
      if (!response) {
        setError('There was an service error. Please try again.');
        return;
      }

      const { redirect_to } = getRedirectParams();
      navigate({ to: '/login', search: { redirect_to } });
    });
  }, [signOut, navigate]);

  useEffect(() => {
    handleSignOut();
  }, [handleSignOut]);

  const hasError = !!error;

  return (
    <Page>
      <PageCard>
        <Box m={0.5}>
          <Icon name="lock" background="first" />
        </Box>
        <Text size="x-large">Sign out</Text>
        <Box mt={6} width={400}>
          <Box width="100%">
            <Button label="Sign Out" fullWidth disabled={!hasError} />
          </Box>
          {hasError && (
            <Box mt={3}>
              <InlineToast message={error} level="error" />
            </Box>
          )}
        </Box>
      </PageCard>
    </Page>
  );
};

export default LogoutPage;
