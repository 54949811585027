import { Select as MuiSelect, MenuItem, Theme } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import FormControl from '../form-control';
import { FormProps } from '..';
import { UiOption } from '@/lib/helpers';
import { createUseStyles } from 'react-jss';

interface Props extends FormProps {
  value: string;
  options: UiOption[];
  onChange: (value: string, event: ChangeEvent<HTMLInputElement>) => void;
  size?: 'medium' | 'small';
}

const useStyles = createUseStyles((theme: Theme) => ({
  select: {
    '&.Mui-disabled fieldset': {
      borderColor: `${theme.palette.grey[600]} !important`,
    },
  },
}));

const Select: FC<Props> = ({
  name,
  value = '',
  options,
  label = '',
  disabled = false,
  autoFocus = false,
  onChange,
  error = '',
  size = 'medium',
  fullWidth = false,
  readonly = false,
  required = false,
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
    onChange(String(event.target.value), event);

  const labelId = `${name}-label`;
  const styles = useStyles();

  return (
    <FormControl label={label} error={error} name={name}>
      <MuiSelect
        labelId={labelId}
        id={name}
        name={name}
        value={value}
        label={label}
        onChange={handleChange}
        disabled={disabled || readonly}
        autoFocus={autoFocus}
        error={!!error}
        size={size}
        fullWidth={fullWidth}
        className={styles.select}
        required={required}
      >
        {options.map(({ value, label, disabled = false }) => {
          return (
            <MenuItem key={value} value={value} disabled={disabled}>
              {label}
            </MenuItem>
          );
        })}
      </MuiSelect>
    </FormControl>
  );
};

export default Select;
