import { FC } from 'react';
import { useCopyToClipboard } from 'usehooks-ts';
import useToast from '@/hooks/use-toast.hook';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import Icon from '../icon';

interface Props {
  value: string;
  className?: string;
  size?: 'medium' | 'small';
  color?: 'active' | 'black';
  message?: string;
}

const useStyles = createUseStyles({
  icon: {
    opacity: 0.7,
    '&:hover': {
      opacity: 1,
    },
  },
});

const CopyClipButton: FC<Props> = ({
  value,
  className,
  size = 'medium',
  color = 'active',
  message = 'Copied to clipboard!',
}) => {
  const { toast } = useToast();
  const [, copy] = useCopyToClipboard();

  const handleCopy = () => {
    copy(value);
    toast(message);
  };
  const styles = useStyles();
  const compClass = classNames(styles.icon, className);

  return <Icon name="copy" size={size} onClick={handleCopy} className={compClass} color={color} />;
};

export default CopyClipButton;
