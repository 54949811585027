import { Box, Slide, Stack } from '@mui/material';
import { FC, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const SlideStep: FC<Props> = ({ children }) => {
  return (
    <Box overflow="hidden">
      <Slide direction="up" in mountOnEnter unmountOnExit>
        <Stack gap={3}>{children}</Stack>
      </Slide>
    </Box>
  );
};

export default SlideStep;
