import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import * as yup from 'yup';
import useFormHandle from '../../hooks/use-form-handle.hook';
import { createUseStyles } from 'react-jss';
import InputControl from '../../components/form/input-control';
import Button from '../../components/button';
import { AuthMethod, execAuthMethod } from '../../lib/services/auth.service';
import InlineToast from '../../components/inline-toast';
import useApp from '../../hooks/use-app.hook';
import { useNavigate } from '@tanstack/react-router';
import usePrior from '../../hooks/use-prior.hook';

const validationSchema = yup.object({
  email: yup.string().email('Enter a valid email').required('Email is required'),
  password: yup.string().required('Password is required'),
});

const useStyles = createUseStyles({
  footer: {
    marginTop: 10,
    marginBottom: 10,
  },
});

const initialValues = {
  email: '',
  password: '',
  rememberMe: [],
};

interface Props {
  authMethod: AuthMethod;
}

const EmailPasswordForm: FC<Props> = ({ authMethod }) => {
  const styles = useStyles();
  const [loginError, setLoginError] = useState('');
  const { signIn, signedIn } = useApp();
  const navigate = useNavigate();

  const formHandle = useFormHandle({
    initialValues: { ...initialValues, email: authMethod.email },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setLoginError('');
      execAuthMethod({
        ...authMethod,
        email: values.email,
        password: values.password,
      }).then((response) => {
        setSubmitting(false);

        if (typeof response === 'string') {
          setLoginError(response);
          return;
        }
        signIn(response!, 'password');
      });
    },
    validateOnChange: false,
  });

  const { handleSubmit, isSubmitting } = formHandle;
  const handleOnEnter = () => handleSubmit();
  const hasError = Boolean(loginError);

  const priorSignedIn = usePrior(signedIn);
  useEffect(() => {
    if (signedIn && !priorSignedIn) {
      navigate({ to: '/home' });
    }
  }, [navigate, signedIn, priorSignedIn]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <InputControl name="email" label="Email" formHandle={formHandle} />
        <InputControl
          name="password"
          label="Password"
          type="password"
          formHandle={formHandle}
          onEnter={handleOnEnter}
          autoFocus
        />
        <Box className={styles.footer}>
          <Button label="Sign In" fullWidth disabled={isSubmitting} onClick={handleSubmit} />
        </Box>
      </form>
      {hasError && (
        <Box mt={2}>
          <InlineToast level="error" message={loginError} />
        </Box>
      )}
    </>
  );
};

export default EmailPasswordForm;
