import { UseQueryResult } from 'react-query';

export type QueryHelper = {
  hasQuery: boolean;
  showLoader: boolean;
  showQueryError: boolean;
  showChildren: boolean;
};

const useQueryHelper = (query?: UseQueryResult, loading = false): QueryHelper => {
  const hasQuery = Boolean(query);
  let showLoader = loading;
  let showQueryError = false;

  // @todo would be nice to use `if (hasQuery)` here instead, but ts can't figure it out :-/
  if (query) {
    const { isError, isLoading } = query || {};
    showLoader = loading || isLoading;
    showQueryError = isError;
  }

  return {
    hasQuery,
    showLoader,
    showQueryError,
    showChildren: !showLoader,
  };
};

export default useQueryHelper;
