import { ChangeEvent, FC } from 'react';
import { FormProps } from '../';
import MuiSwitch from '@mui/material/Switch';
import FormControl from '../form-control';
import { createUseStyles } from 'react-jss';
import { Box, Stack, Theme } from '@mui/material';

interface Props extends FormProps {
  checked: boolean;
  onChange: (checked: boolean, event: ChangeEvent<HTMLInputElement>) => void;
}

const useStyles = createUseStyles((theme: Theme) => ({
  switch: {
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.action.active,
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.grey[100],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.grey[400],
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  },
}));

const Switch: FC<Props> = ({
  label,
  checked,
  onChange,
  error,
  name,
  fullWidth,
  disabled,
  readonly,
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
    onChange(event.target.checked, event);

  const styles = useStyles();
  const hasLabel = Boolean(label);

  return (
    <FormControl label="" error={error} name={name} fullWidth={fullWidth}>
      <Stack direction="row" gap={2} alignItems="center">
        {hasLabel && <Box>{label}</Box>}
        <MuiSwitch
          checked={checked}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'controlled' }}
          focusVisibleClassName=".Mui-focusVisible"
          disableRipple
          className={styles.switch}
          disabled={disabled || readonly}
        />
      </Stack>
    </FormControl>
  );
};

export default Switch;
