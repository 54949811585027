import {
  redirect,
  Navigate,
  createRootRoute,
  createRouter,
  createRoute,
} from '@tanstack/react-router';
import DOMPurify from 'dompurify';
import App from '../App';
import LoginPage from '../pages/login';
import LogoutPage from '../pages/logout';
import NotFoundPage from '../pages/not-found';
import { AuthProvider, getRedirectParams } from '../lib/services/auth.service';
import MagicLinkPage from '@/pages/login/link';
import HomePage from '@/pages/home';
import SplashPage from '@/pages/splash';
import ErrorPage from '@/pages/error';

/*
 * Note: There is significant handling of the request based on the path which
 * happens in @/context/app/index.tsx, before the router even renders. Check
 * the useEffect hooks in that file for further notes.
 */

const rootRoute = createRootRoute({
  component: App,
});

const excludeAuth = () => {
  if (AuthProvider.user) {
    throw redirect({
      to: '/home',
      search: getRedirectParams(),
    });
  }
};

const requireAuth = () => {
  if (!AuthProvider.user) {
    throw redirect({
      to: '/login',
      search: getRedirectParams(),
    });
  }
};

export type SplashPageAction =
  | 'error'
  | 'block'
  | 'redirect'
  | 'downtime'
  | 'portal-access'
  | 'admin-access'
  | 'partner-access';

const isSplashPageAction = (value: string): value is SplashPageAction => {
  return [
    'error',
    'block',
    'redirect',
    'downtime',
    'portal-access',
    'admin-access',
    'partner-access',
  ].includes(value);
};
type SplashPageSearch = {
  action: SplashPageAction;
  destination: string;
  traceId: string;
  code: string;
};

const routes = [
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/',
    component: () => {
      return <Navigate to="/login" search={getRedirectParams()} />;
    },
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/login',
    component: LoginPage,
    beforeLoad: excludeAuth,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/login/link',
    component: MagicLinkPage,
    beforeLoad: excludeAuth,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/logout',
    component: LogoutPage,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/home',
    component: HomePage,
    beforeLoad: requireAuth,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/splash',
    component: SplashPage,
    validateSearch: ({
      action,
      destination,
      request_id,
      trace_id,
      code,
    }: Record<string, unknown>): SplashPageSearch => {
      const compTraceId = trace_id || request_id || '';
      return {
        action: (action as SplashPageAction) || '',
        destination: destination ? DOMPurify.sanitize(String(destination)) : '',
        traceId: compTraceId ? DOMPurify.sanitize(String(compTraceId)) : '',
        code: code ? DOMPurify.sanitize(String(code)) : '',
      };
    },
    beforeLoad: ({ search }) => {
      if (!isSplashPageAction(search.action as string)) {
        throw redirect({ to: '/' });
      }

      requireAuth();
    },
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/error',
    component: ErrorPage,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '*',
    component: NotFoundPage,
    beforeLoad: requireAuth,
  }),
];

const routeTree = rootRoute.addChildren(routes);

const router = createRouter({ routeTree });

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

export default router;
