import { SnackbarOrigin, VariantType, useSnackbar } from 'notistack';
import { useCallback } from 'react';

export const TOAST_CONFIG = {
  anchorOrigin: { horizontal: 'right', vertical: 'bottom' } as SnackbarOrigin,
  autoHideDuration: 4000,
  preventDuplicate: true,
  variant: 'info' as VariantType,
};

const useToast = () => {
  const { enqueueSnackbar } = useSnackbar();

  const toast = useCallback(
    (message: string, toastType?: VariantType) => {
      enqueueSnackbar(message, {
        ...TOAST_CONFIG,
        variant: toastType || 'info',
      });
    },
    [enqueueSnackbar]
  );

  const errorToast = useCallback((message: string) => toast(message, 'error'), [toast]);

  return {
    toast,
    errorToast,
  };
};

export default useToast;
