import { Theme } from '@mui/material';
import MuiCard from '@mui/material/Card';
import classNames from 'classnames';
import { FC, ReactNode, useState } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
  hover?: boolean;
}

const useStyles = createUseStyles((theme: Theme) => ({
  card: {
    padding: 20,
    border: `1px solid ${theme.palette.grey[300]}`,
    '&.clickable': {
      cursor: 'pointer',
    },
  },
}));

const Card: FC<Props> = ({ children, className = '', onClick, hover = false }) => {
  const styles = useStyles();
  const compClass = classNames(styles.card, className, { clickable: Boolean(onClick) });
  const [elevation, setElevation] = useState(0);

  const handleClick = () => {
    onClick && onClick();
  };

  const handleHover = (enabled: boolean) => {
    if (!hover) {
      return;
    }
    setElevation(enabled ? 1 : 0);
  };

  return (
    <MuiCard
      elevation={elevation}
      className={compClass}
      onClick={handleClick}
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
    >
      {children}
    </MuiCard>
  );
};

export default Card;
