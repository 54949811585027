import { FC } from 'react';
import { Box, Stack, Theme } from '@mui/material';
import UserBadge from '../user-badge';
import { AuthSite, AuthUser, getRoleLabel, getSiteUrl } from '@/lib/services/auth.service';
import { getFirstLastName } from '@/lib/helpers';
import Text from '../text';
import Button from '../button';
import { useNavigate } from '@tanstack/react-router';
import { createUseStyles } from 'react-jss';
import Menu from '../menu';
import { getUrlQueryParam } from '@/lib/url-helpers';
import { BrandingModel } from '@/lib/models/branding.model';

interface Props {
  user: AuthUser;
  branding: BrandingModel;
}

const getRedirectSite = (action: string): string => {
  let site: AuthSite | null = null;
  switch (action) {
    case 'admin-access':
      site = 'admin';
      break;
    case 'partner-access':
      site = 'partner';
      break;
    case 'portal-access':
      site = 'portal';
      break;
  }

  if (!site) {
    return '';
  }

  return getSiteUrl(site);
};

const useStyles = createUseStyles((theme: Theme) => ({
  userCard: {
    position: 'relative',
  },
  logoutButton: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 12,
  },
  footer: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    marginTop: 16,
    paddingTop: 16,
  },
}));

const UserMenu: FC<Props> = ({ user, branding }) => {
  const navigate = useNavigate();
  const styles = useStyles();

  const { displayName, email, role } = user;
  const username = displayName || '';
  const { first, last } = getFirstLastName(username);
  const hasName = Boolean(first) || Boolean(last);

  const hasElevatedRole = role !== 'org:user';
  const roleLabel = getRoleLabel(user);

  let NameEl = <Text size="large">{email}</Text>;

  if (hasName) {
    NameEl = (
      <Stack gap={0.4}>
        <Text size="large">{[first, last].join(' ')}</Text>
        <Text color="grey">{email}</Text>
      </Stack>
    );
  }

  const handleSignOut = () => {
    const action = getUrlQueryParam('action');
    const search = action ? { redirect_to: getRedirectSite(action) } : {};
    navigate({ to: '/logout', search });
  };

  return (
    <Menu trigger={<UserBadge user={user} />}>
      {NameEl}

      <Stack gap={0.4} mt={2}>
        <Text>{branding?.name}</Text>
        {hasElevatedRole && <Text color="grey">{roleLabel}</Text>}
      </Stack>

      <Box className={styles.footer} display="flex" justifyContent="flex-end">
        <Button
          label="Sign Out"
          type="delete"
          icon="log-out"
          onClick={handleSignOut}
          size="small"
        />
      </Box>
    </Menu>
  );
};

export default UserMenu;
