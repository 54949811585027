import { BaseModel } from './base.model';
import { JsonObject } from '../helpers';

export class BrandingModel extends BaseModel {
  name: string;
  lightThemeLogo: string;
  supportEmail: string;
  favicon: string;

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof BrandingModel, unknown>;

    this.name = (data.name as string) || '';

    this.lightThemeLogo = (data.lightThemeLogo as string) || '';

    this.supportEmail = (data.supportEmail as string) || '';

    this.favicon = (data.favicon as string) || '';
  }

  get enabled(): boolean {
    return !!this.lightThemeLogo;
  }
}
