import { FC, useState } from 'react';
import { Box, Theme } from '@mui/material';
import { AuthMethod, execAuthMethod } from '../../lib/services/auth.service';
import AuthMethodButton from './auth-method-button';
import FlatButton from '../../components/flat-button';
import IdentForm from './ident-form';
import EmailPasswordForm from './email-password-form';
import SlideStep from './slide-step';
import useApp from '../../hooks/use-app.hook';
import InlineToast from '../../components/inline-toast';
import { LoadState } from '@/lib/helpers';
import { createUseStyles } from 'react-jss';
import Loader from '@/components/loader';

type LoginStep = 'ident' | 'choose' | 'password';

type LoginConfig = {
  step: LoginStep;
  email: string;
  authMethod: AuthMethod | null;
  authMethods: AuthMethod[];
  message: string;
  error: string;
};

const useStyles = createUseStyles((theme: Theme) => ({
  goBack: {
    marginTop: 10,
    paddingTop: 10,
    borderTop: `1px solid ${theme.palette.grey[200]}`,
  },
}));

const LoginForm: FC = () => {
  const { signInError, resetSignIn } = useApp();
  const styles = useStyles();

  const [loginConfig, setLoginConfig] = useState<LoginConfig>({
    step: 'ident',
    email: '',
    authMethod: null,
    authMethods: [],
    message: '',
    error: '',
  });

  const [loadState, setLoadState] = useState<LoadState>('unloaded');

  const handleReset = () => {
    setLoadState('unloaded');
    setLoginConfig({
      step: 'ident',
      email: '',
      authMethod: null,
      authMethods: [],
      message: '',
      error: '',
    });
  };

  const handleAuthMethodChange = (authMethod: AuthMethod) => {
    const { type } = authMethod;
    switch (type) {
      case 'OIDC':
      case 'SAML':
        setLoadState('loading');
        // we are redirecting away from the page here, so no need to further update state, etc
        execAuthMethod(authMethod);
        break;
      case 'password':
        setLoginConfig((old) => ({
          ...old,
          step: 'password',
          message: '',
          error: '',
          authMethod: {
            ...authMethod,
            email: old.email,
          },
        }));
        break;
      case 'magicLink':
        setLoadState('loading');
        execAuthMethod(authMethod).then((response) => {
          const emailSent = response === null;
          const message = emailSent ? 'If that login was found, an email was sent.' : '';
          const error = emailSent ? '' : (response as string);
          setLoginConfig((old) => ({
            ...old,
            message,
            error,
          }));
          setLoadState('loaded');
        });
        break;
    }
  };

  const handleIdentChange = (email: string, authMethods: AuthMethod[]) => {
    resetSignIn();

    if (!authMethods.length) {
      setLoginConfig((old) => ({
        ...old,
        step: 'ident',
        authMethods: [],
        authMethod: null,
        error: 'That login was not found',
        message: '',
      }));

      return;
    }
    setLoginConfig((old) => ({
      ...old,
      step: 'choose',
      authMethods,
      authMethod: null,
      email,
      error: '',
      message: '',
    }));
  };

  const { step, authMethods, authMethod, message, error } = loginConfig;
  const errorMessage = signInError || error;
  const hasError = Boolean(errorMessage);
  const hasMessage = Boolean(message);
  const loading = loadState === 'loading';

  const GoBackEl = (
    <Box className={styles.goBack}>
      <FlatButton label="Go Back" icon="arrow-left" onClick={handleReset} />
    </Box>
  );

  return (
    <Box>
      {step === 'ident' && <IdentForm onChange={handleIdentChange} />}

      {step === 'choose' && (
        <SlideStep>
          {authMethods.map((authMethod) => {
            return (
              <AuthMethodButton
                key={`${authMethod.type}-${authMethod.providerId}`}
                authMethod={authMethod}
                onClick={() => handleAuthMethodChange(authMethod)}
                disabled={loadState !== 'unloaded'}
              />
            );
          })}
          {GoBackEl}
        </SlideStep>
      )}

      {step === 'password' && (
        <SlideStep>
          <EmailPasswordForm authMethod={authMethod!} />\ {GoBackEl}
        </SlideStep>
      )}

      <Box mt={3}>
        {loading && <Loader />}
        {!loading && hasError && <InlineToast message={errorMessage} level="error" />}
        {!loading && hasMessage && <InlineToast message={message} level="info" />}
      </Box>
    </Box>
  );
};

export default LoginForm;
