import { Alert, AlertColor } from '@mui/material';
import { FC } from 'react';

interface Props {
  level?: AlertColor;
  message: string;
}

const InlineToast: FC<Props> = ({ level = 'info', message }) => {
  return <Alert severity={level}>{message}</Alert>;
};

export default InlineToast;
