import { FC, ForwardedRef } from 'react';
import lodashGet from 'lodash/get';
import Checkbox from '../checkbox';
import TextInput from '../text-input';
import { FormikValues } from 'formik';
import { FormProps } from '..';
import Switch from '../switch';
import Select from '../select';
import { FormHandle } from '../../../hooks/use-form-handle.hook';
import * as yup from 'yup';
import SelectMulti from '../select-multi';
import { DateRangeValue, UiOption } from '@/lib/helpers';

export type InputControlType =
  | 'text'
  | 'password'
  | 'email'
  | 'checkbox'
  | 'textarea'
  | 'switch'
  | 'select'
  | 'select-multi';

export type InputControlValue = null | string | number | boolean | Date | DateRangeValue | string[];

// @todo ts won't catch if you do something silly like pass props like { type: 'text', options: [...]}. in other words, we need some way to validate props against the known type
interface Props extends FormProps {
  type?: InputControlType;
  formHandle: FormHandle<FormikValues>;
  autoComplete?: string;
  onEnter?: (value: string) => void;
  rows?: number;
  options?: UiOption[];
  multiple?: boolean;
  inputRef?: ForwardedRef<HTMLInputElement>;
  onChange?: (val: InputControlValue) => void;
}

// @todo typing?
const getErrorText = (formikError: string | string[]): string => {
  if (!formikError) {
    return '';
  }

  if (Array.isArray(formikError)) {
    return formikError.join(', ');
  }

  // @todo handle more types?
  return String(formikError);
};

const fieldIsRequired = (fieldName: string, validationSchema: yup.ObjectSchema<yup.AnyObject>) => {
  const field = lodashGet(validationSchema.fields, fieldName.replace(/\./, '.fields.'));

  if (!field) {
    return false;
  }

  // @todo oof
  /* eslint-disable */
  const isRequired = !!(field as any).tests.find((test: any) => test.OPTIONS.name === 'required');
  /* eslint-enable */

  return isRequired;
};

const InputControl: FC<Props> = ({
  formHandle,
  name,
  label,
  disabled = false,
  fullWidth = true,
  type = 'text',
  autoComplete = '',
  autoFocus = false,
  onEnter = () => null,
  rows,
  options = [],
  size = 'medium',
  inputRef,
  onChange,
  readonly = false,
}) => {
  const {
    values,
    errors,
    // touched,
    setFieldValue,
    setFieldTouched,
    validationSchema,
    validateOnChange,
  } = formHandle;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const value = lodashGet(values, name);
  // const touch = lodashGet(touched, name);
  const error = getErrorText(lodashGet(errors, name) as string | string[]);
  const required = fieldIsRequired(name, validationSchema);

  const handleFormChange = (val: InputControlValue) => {
    setFieldValue(name, val, validateOnChange);

    // https://github.com/jaredpalmer/formik/issues/2059
    setTimeout(() => {
      setFieldTouched(name, validateOnChange);
    });

    onChange && onChange(val);
  };

  const formProps: FormProps = {
    name,
    label,
    disabled,
    autoFocus,
    error,
    fullWidth,
    required,
    ref: inputRef,
    readonly,
  };

  switch (type) {
    case 'text':
    case 'email':
    case 'password':
    case 'textarea':
    default: {
      let inputType = 'text';
      if (['password', 'textarea'].includes(type)) {
        inputType = type;
      }

      return (
        <TextInput
          {...formProps}
          onChange={handleFormChange}
          onEnter={onEnter}
          value={value as string}
          type={inputType}
          autoComplete={autoComplete}
          rows={rows}
        />
      );
    }
    case 'checkbox': {
      let checked = value as boolean;

      // formik passes an array for some reason
      if (Array.isArray(value)) {
        checked = Boolean(value.length) && value[0] === 'on';
      }

      return <Checkbox {...formProps} checked={checked} onChange={handleFormChange} />;
    }
    case 'switch': {
      let checked = value as boolean;

      // formik passes an array for some reason
      if (Array.isArray(value)) {
        checked = Boolean(value.length) && value[0] === 'on';
      }

      return <Switch {...formProps} checked={checked} onChange={handleFormChange} />;
    }
    case 'select':
      return (
        <Select
          {...formProps}
          value={value as string}
          options={options}
          onChange={handleFormChange}
          size={size}
          fullWidth
        />
      );
    case 'select-multi': {
      const handleSelectMultiChange = (options: UiOption[]) => {
        handleFormChange(options.map(({ value }) => value));
      };

      return (
        <SelectMulti
          {...formProps}
          value={value as string[]}
          options={options}
          onChange={handleSelectMultiChange}
        />
      );
    }
  }
};

export default InputControl;
