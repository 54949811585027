import { FC, ReactNode } from 'react';
import Page from '../../components/page';
import { createUseStyles } from 'react-jss';
import Card from '@/components/card';
import { Stack } from '@mui/material';
import classNames from 'classnames';

interface Props {
  children: ReactNode;
  height?: number | string;
  className?: string;
}

const useStyles = createUseStyles({
  container: {
    margin: '8px auto',
    width: 500,
  },
});

const PageCard: FC<Props> = ({ children, height, className }) => {
  const styles = useStyles();
  const compClass = classNames(styles.container, className);

  return (
    <Page>
      <Card className={compClass}>
        <Stack alignItems="center" justifyContent="center" gap={2} minHeight={height}>
          {children}
        </Stack>
      </Card>
    </Page>
  );
};

export default PageCard;
