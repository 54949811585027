import { Box, Stack, Theme, Toolbar } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';
import BrandLogo from '../brand-logo';
import UserMenu from '../user-menu';
import useApp from '@/hooks/use-app.hook';

const useStyles = createUseStyles<string>((theme: Theme) => ({
  header: {
    width: '100%',
    backgroundColor: '#fff',
    borderBottom: '1px solid #EFEFEF',
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  headerInner: {
    padding: '0px 24px',
    width: '100%',
    boxSizing: 'border-box',
  },
  logoLink: {
    display: 'block',
    '&:hover': {
      opacity: 1,
    },
  },
}));

const AppHeader: FC = () => {
  const styles = useStyles();
  const { signedIn, user, branding } = useApp();

  return (
    <AppBar className={styles.header}>
      <Toolbar className={styles.headerInner}>
        <Box minWidth={250}>
          <a href="/" className={styles.logoLink}>
            <BrandLogo />
          </a>
        </Box>
        <Stack direction="row" flexGrow={1} justifyContent="flex-end">
          {signedIn && <UserMenu user={user!} branding={branding} />}
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;
