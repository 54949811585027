import { FC, ReactNode, useEffect, useState } from 'react';
import { SplashPageAction } from '@/router';
import { Box, LinearProgress, Stack } from '@mui/material';
import Text from '@/components/text';
import CopyClipButton from '@/components/copy-clip-button';
import Icon from '@/components/icon';
import Button from '@/components/button';
import { useInterval } from 'usehooks-ts';
import { getPortalUrl } from '@/lib/helpers';
import useApp from '@/hooks/use-app.hook';
import { capitalize } from 'lodash';
import SupportEmail from './support-email';

interface Props {
  action: SplashPageAction;
  destination?: string;
  traceId?: string;
  code?: string;
}

const SplashMessage: FC<Props> = ({ action, destination = '', traceId = '', code = '' }) => {
  const [progress, setProgress] = useState(0);
  const { setPageTitle, branding } = useApp();

  const handleRedirect = () => {
    setProgress(100);
    setTimeout(() => {
      window.location.href = getPortalUrl();
    }, 800);
  };

  useEffect(() => {
    setPageTitle(capitalize(action));
  }, [setPageTitle, action]);

  let ContentEl: ReactNode = null;
  let title = '';
  let IconEl: ReactNode = null;

  const hasDestination = Boolean(destination);

  const orgName = branding?.name || '';
  const supportEmail = branding?.supportEmail || '';

  const hasCode = Boolean(code);
  const hasTraceId = Boolean(traceId);

  const TraceIdEl = hasTraceId ? (
    <Stack gap={1}>
      <Box>Trace Id</Box>
      <Stack direction="row" gap={1} alignItems="center">
        <Text bold>{traceId}</Text>
        <CopyClipButton
          value={traceId}
          color="black"
          size="small"
          message="Trace Id copied to clipboard"
        />
      </Stack>
    </Stack>
  ) : null;

  switch (action) {
    case 'admin-access':
      title = 'Not Approved for Access';
      IconEl = <Icon name="block" size="x-large" color="error" />;

      ContentEl = (
        <>
          <Box>Your user account is not approved for access to the admin site.</Box>

          <SupportEmail
            emailAddress={supportEmail}
            description="Please contact IT support if you require access"
          />
        </>
      );

      break;

    case 'portal-access':
      title = 'Portal Disabled';
      IconEl = <Icon name="block" size="x-large" color="error" />;

      ContentEl = (
        <>
          <Box>Your user account is not setup for access to the Portal.</Box>

          <SupportEmail
            emailAddress={supportEmail}
            description="Please contact IT support if you require access"
          />
        </>
      );

      break;

    case 'partner-access':
      title = 'Not Approved for Access';
      IconEl = <Icon name="block" size="x-large" color="error" />;

      ContentEl = (
        <>
          <Box>Your user account is not approved for access to the partner site.</Box>

          <SupportEmail
            emailAddress={supportEmail}
            description="Please contact IT support if you require access"
          />
        </>
      );

      break;

    case 'error':
      title = 'Error';
      IconEl = <Icon name="network-error" size="x-large" color="error" />;
      ContentEl = (
        <>
          <Box>There was an unexpected error that prevented your request from being completed.</Box>

          {TraceIdEl}

          {hasCode && (
            <Stack gap={1}>
              <Box>Error Code</Box>
              <Stack direction="row" gap={1} alignItems="center">
                <Text bold>{code}</Text>
              </Stack>
            </Stack>
          )}

          <SupportEmail emailAddress={supportEmail} />
        </>
      );

      break;
    case 'downtime':
      title = 'Downtime';
      IconEl = <Icon name="downtime" size="x-large" color="warning" />;
      ContentEl = (
        <>
          <Box>There is active downtime for a service required to handle your request.</Box>

          {hasDestination && (
            <Stack gap={1}>
              <Box>Destination</Box>
              <Stack direction="row" gap={1} alignItems="center">
                <Text bold>{destination}</Text>
              </Stack>
            </Stack>
          )}

          {hasCode && (
            <Stack gap={1}>
              <Box>Issue Id</Box>
              <Stack direction="row" gap={1} alignItems="center">
                <Text bold>{code}</Text>
                <CopyClipButton
                  value={code}
                  color="black"
                  size="small"
                  message="Issue Id copied to clipboard"
                />
              </Stack>
            </Stack>
          )}
        </>
      );
      break;
    case 'block':
      title = 'Site Blocked';
      IconEl = <Icon name="block" size="x-large" color="error" />;
      ContentEl = (
        <>
          <Box>{orgName || 'Your organization'} does not allow access to:</Box>
          <Box>
            <Text inline color="grey" size="large">
              {hasDestination ? destination : 'unknown site'}
            </Text>
          </Box>

          {TraceIdEl}

          <SupportEmail emailAddress={supportEmail} />
        </>
      );

      break;
    case 'redirect':
      {
        title = 'Redirect';
        IconEl = <Icon name="redirect" size="x-large" color="black" />;

        ContentEl = (
          <>
            <Box textAlign="center">
              You are being redirected{' '}
              {hasDestination ? (
                <>
                  from{' '}
                  <Text inline bold>
                    {destination}
                  </Text>
                </>
              ) : (
                ''
              )}{' '}
              to the {orgName} portal for secure Generative AI.
            </Box>
            <Box textAlign="center" mt={4}>
              <Button label="Go to the Portal now" onClick={handleRedirect} />
            </Box>
            {hasDestination && (
              <Box mt={8} textAlign="center">
                <Text color="grey">{destination}</Text>
              </Box>
            )}
            <Box mt={4}>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
          </>
        );
      }
      break;
  }

  useInterval(
    () => {
      setProgress(progress + 1);
      if (progress >= 99) {
        handleRedirect();
      }
    },
    action === 'redirect' && progress < 100 ? 100 : null
  );

  return (
    <Stack alignItems="center" justifyContent="flex-start" gap={2} width="100%">
      <Box>{IconEl}</Box>
      <Box>
        <Text size="x-large">{title}</Text>
      </Box>
      <Stack mt={2} width="100%" gap={3}>
        {ContentEl}
      </Stack>
    </Stack>
  );
};

export default SplashMessage;
