import { FC } from 'react';
import Page, { PageProps } from '../../components/page';
import useApp from '../../hooks/use-app.hook';
import { Box } from '@mui/material';
import Text from '@/components/text';
import Button from '@/components/button';
import { useNavigate } from '@tanstack/react-router';
import UserBadge from '@/components/user-badge';
import PageCard from '@/components/page-card';

const HomePage: FC<PageProps> = () => {
  const { user } = useApp();

  const navigate = useNavigate();

  const handleLogout = () => {
    navigate({ to: '/logout' });
  };

  return (
    <Page>
      <PageCard>
        <Box>
          <UserBadge user={user!} />
        </Box>
        <Box>
          <Text size="x-large">Signed In</Text>
        </Box>

        <Box mt={2}>You are signed in</Box>
        <Text color="active" dotdot>
          {user?.email || user?.displayName}
        </Text>

        <Box mt={10}>
          <Button label="Sign Out" type="delete" icon="log-out" onClick={handleLogout} />
        </Box>
      </PageCard>
    </Page>
  );
};

export default HomePage;
