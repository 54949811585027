import { FC, useEffect, useState } from 'react';
import * as yup from 'yup';
import Page, { PageProps } from '../../components/page';
import { Box } from '@mui/material';
import Icon from '../../components/icon';
import { createUseStyles } from 'react-jss';
import Text from '../../components/text';
import { checkAuthLink, getMagicLinkEmail, getRedirectUrl } from '@/lib/services/auth.service';
import useApp from '@/hooks/use-app.hook';
import { useNavigate } from '@tanstack/react-router';
import InlineToast from '@/components/inline-toast';
import useFormHandle from '@/hooks/use-form-handle.hook';
import InputControl from '@/components/form/input-control';
import Button from '@/components/button';

const useStyles = createUseStyles({
  lockIcon: {
    margin: 4,
  },
  container: {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  footer: {
    marginTop: 10,
    marginBottom: 10,
  },
});

const validationSchema = yup.object({
  email: yup.string().email('Enter a valid email').required('Email is required'),
});

const MagicLinkPage: FC<PageProps> = () => {
  const styles = useStyles();
  const { signInError, signInMethod, signIn, signedIn } = useApp();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  const formHandle = useFormHandle({
    initialValues: { email: '' },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setErrorMessage('');

      const { user, error } = await checkAuthLink(values.email);

      setSubmitting(false);

      if (error) {
        setErrorMessage(error);
        return;
      }

      signIn(user!, 'magicLink');
    },
    validateOnChange: false,
  });

  useEffect(() => {
    if (signedIn) {
      const redirectUrl = getRedirectUrl();
      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else {
        navigate({ to: '/home' });
      }
      return;
    }
    if (signInMethod === 'magicLink') {
      if (signInError) {
        const email = getMagicLinkEmail();
        // email missing, show the form
        if (!email) {
          return;
        }
      }
    }

    navigate({ to: '/login' });
  }, [signInError, signInMethod, navigate, signedIn]);

  const { handleSubmit, isSubmitting } = formHandle;
  const handleOnEnter = () => handleSubmit();
  const hasError = Boolean(errorMessage);

  return (
    <Page>
      <Box className={styles.container}>
        <Icon name="email-lock" background="second" className={styles.lockIcon} />
        <Text size="x-large">Complete Sign-in</Text>
        <Box mt={6} width="400px">
          <form onSubmit={handleSubmit}>
            <InputControl
              name="email"
              label="Email"
              formHandle={formHandle}
              autoFocus
              onEnter={handleOnEnter}
            />
            <Box className={styles.footer}>
              <Button label="Sign In" fullWidth disabled={isSubmitting} onClick={handleSubmit} />
            </Box>
          </form>
        </Box>

        <Box mt={3}>{hasError && <InlineToast message={errorMessage} level="error" />}</Box>
      </Box>
    </Page>
  );
};

export default MagicLinkPage;
