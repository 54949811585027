import { FC } from 'react';
import { AuthMethod, AuthType } from '../../lib/services/auth.service';
import Button from '../../components/button';
import { IconType } from '../../components/icon';

interface Props {
  authMethod: AuthMethod;
  onClick: () => void;
  disabled: boolean;
}

const Icons: Record<AuthType, IconType> = {
  OIDC: 'lock-person',
  SAML: 'lock-person',
  password: 'password',
  magicLink: 'email-lock',
};

const AuthMethodButton: FC<Props> = ({ authMethod, onClick, disabled }) => {
  const { name, type } = authMethod;
  return (
    <Button
      fullWidth
      disabled={disabled}
      onClick={onClick}
      size="large"
      icon={Icons[type]}
      label={name}
    />
  );
};

export default AuthMethodButton;
