import { object, ObjectSchema, ValidationError, ObjectShape } from 'yup';
import pick from 'lodash/pick';
import { JsonObject } from '../helpers';
import { NosqlDocModel } from './nosql-doc.model';

export class BaseModel extends NosqlDocModel {
  schema: ObjectSchema<object> = object({});

  get _props(): Record<string, unknown> {
    const props: Record<string, unknown> = {};
    const protectedFields = ['schema'];

    Object.getOwnPropertyNames(this)
      .filter((propName) => {
        if (protectedFields.includes(propName)) {
          return false;
        }
        const descriptor = Object.getOwnPropertyDescriptor(this, propName);
        return descriptor && typeof descriptor.get !== 'function';
      })
      .forEach((prop: string) => (props[prop] = this[prop as keyof this]));

    return props;
  }

  get _json(): JsonObject {
    return JSON.parse(JSON.stringify(this._props)) as JsonObject;
  }

  // protected init(data: Record<keyof T, unknown>) {
  //   Object.keys(this._props).forEach(modelProp => {
  //     const safeKey = modelProp as keyof T;
  //     const dataVal = data[safeKey];
  //     if (typeof dataVal !== 'undefined') {
  //       Object.assign(this, { [safeKey]: dataVal });
  //     }
  //   });
  // }

  propSlice(fields: string[]): Record<string, unknown> {
    return pick(this._props, fields);
  }

  async validate(): Promise<Record<string, string | string[]> | null> {
    try {
      await this.schema.validate(this._json, { abortEarly: false });
      return null;
    } catch (err) {
      const validationError = err as ValidationError;
      const propErrors: Record<string, string | string[]> = {};
      validationError.inner.forEach(({ path, errors }) => {
        if (path) {
          propErrors[path] = errors;
        }
      });
      return propErrors;
    }
  }

  async isValid(): Promise<boolean> {
    return this.schema.isValid(this._json);
  }

  schemaSlice(fields: string[]): ObjectSchema<object> {
    if (!this.schema?.fields) {
      return object({});
    }
    return object(pick(this.schema.fields as ObjectShape, fields));
  }
}
