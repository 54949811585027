import { FC, useMemo } from 'react';
import Page, { PageProps } from '../../components/page';
import { Box, Stack, Theme } from '@mui/material';
import Icon from '../../components/icon';
import Text from '../../components/text';
import LoginForm from './login-form';
import PageCard from '@/components/page-card';
import { getSite } from '@/lib/services/auth.service';
import { createUseStyles } from 'react-jss';
import SurePathAILogo from '@/assets/logo-carbon-0924.svg';
import useApp from '@/hooks/use-app.hook';

const useStyles = createUseStyles((theme: Theme) => ({
  siteContainer: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    padding: '12px 16px',
    marginBottom: 16,
  },
  loginCard: {
    padding: 0,
  },
}));

const LoginPage: FC<PageProps> = () => {
  const styles = useStyles();
  const { branding } = useApp();

  const [siteLabel, showLogo] = useMemo(() => {
    const site = getSite();
    switch (site) {
      case 'admin':
        return ['Admin Console', true];
      case 'partner':
        return ['Partner Console', true];
      case 'portal':
        return ['Portal', false];
    }

    return ['', false];
  }, []);

  const hasSiteLabel = !!siteLabel;

  return (
    <Page>
      <PageCard className={styles.loginCard}>
        {hasSiteLabel && (
          <Stack
            width="100%"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            className={styles.siteContainer}
          >
            {showLogo && <img src={SurePathAILogo} alt="SurePath AI" width={130} />}
            {!showLogo && <Text>{branding.name}</Text>}
            <Text>{siteLabel}</Text>
          </Stack>
        )}

        <Stack alignItems="center" justifyContent="center" gap={2} p={2}>
          <Box m={0.5}>
            <Icon name="lock" background="first" />
          </Box>
          <Text size="x-large">Sign in</Text>
          <Box mt={6} width={400} minHeight={260}>
            <LoginForm />
          </Box>
        </Stack>
      </PageCard>
    </Page>
  );
};

export default LoginPage;
