import { FC, ReactNode, useEffect } from 'react';
import useApp from '../../hooks/use-app.hook';
import { Box } from '@mui/material';
import { UseQueryResult } from 'react-query';
import useQueryHelper from '../../hooks/use-query-helper';
import Loader from '../loader';
import { BreadCrumb } from '../app-header/helpers';

export interface PageProps {
  children?: ReactNode;
  title?: string;
  query?: UseQueryResult;
  breadcrumb?: BreadCrumb | BreadCrumb[];
}

const Page: FC<PageProps> = ({ children, title = '', breadcrumb, query }) => {
  const { setPageTitle } = useApp();
  useEffect(() => {
    setPageTitle(title, breadcrumb);
  }, [setPageTitle, title, breadcrumb]);

  const { showLoader } = useQueryHelper(query);

  // reset scroll
  useEffect(() => {
    const nodes = document.getElementsByTagName('main');
    if (nodes.length) {
      nodes[0].scrollTo(0, 0);
    }
  }, []);

  return (
    <Box height="100%" width="100%">
      {showLoader && <Loader />}
      {!showLoader && <Box pb={5}>{children}</Box>}
    </Box>
  );
};

export default Page;
