import { QueryClient } from 'react-query';
import { enqueueSnackbar } from 'notistack';
import { TOAST_CONFIG } from '../hooks/use-toast.hook';
import { addError } from './datadog';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 4000, // how long is data considered fresh for?
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      retry: 1,
      onError: (error: Error) => {
        if (error.cause === 401) {
          window.location.reload();
          return;
        }
        addError(error);
        enqueueSnackbar(error.message, { ...TOAST_CONFIG, variant: 'error' });
      },
    },
  },
});

export default queryClient;
